import formatMessage from './formatMessage'
import formatPrice, { type FormatPriceOptions } from './formatPrice'
import getValue from './getValue'


class IntlManager {
  public locale: Intl.Locale
  public currency: Intl.CurrencyCode

  constructor(config: Intl.ManagerConfig) {
    this.locale = new Intl.Locale(config.locale)
    this.currency = config.currency
  }

  public formatMessage = (message: Intl.Message | string, values?: Intl.MessageValues) => {
    return formatMessage(this.locale, message, values)
  }

  public formatPrice = (value: Intl.PriceValue, options: FormatPriceOptions = {}) => {
    const finalOptions: FormatPriceOptions = {
      ...options,
      currency: options.currency || this.currency,
    }

    if (value && typeof value === 'object') {

      const { amount, amountCents, currencyCode } = value

      if (currencyCode) {
        finalOptions.currency = currencyCode
      }

      if (amountCents) {
        finalOptions.isCents = true
        return formatPrice(this.locale.baseName, amountCents, finalOptions)
      }

      return formatPrice(this.locale.baseName, Number(amount || 0), finalOptions)
    }

    return formatPrice(this.locale.baseName, Number(value || 0), finalOptions)
  }

  public getPriceInCents = (value: Intl.PriceValue): number => {
    if (value && typeof value === 'object') {
      const { amount, amountCents } = value
      if (amountCents) {
        return amountCents
      }

      return Number(amount || 0) / 100
    }

    return Number(value || 0)
  }

  public getPercentDiff = (firstValue: Intl.PriceValue, secondValue: Intl.PriceValue) => {
    const firstAmount = this.getPriceInCents(firstValue)
    const secondAmount = this.getPriceInCents(secondValue)

    return `${Math.round(((secondAmount - firstAmount) / secondAmount) * 100)}%`
  }

  public getPairValuesAndDiff = (
    firstValue: Intl.PriceValue,
    secondValue: Intl.PriceValue,
    options: FormatPriceOptions = {}
  ) => {
    return [
      this.formatPrice(firstValue, options),
      this.formatPrice(secondValue, options),
      this.getPercentDiff(firstValue, secondValue),
    ]
  }

  public getValue = <T>(value: Intl.Value<T>) => {
    return getValue(this.locale, value)
  }
}

export default IntlManager
