const getValue = <T>(locale: Intl.Locale, value: Intl.Value<T>): T => {
  let fLocale = 'en'

  if (value[locale.baseName]) {
    fLocale = locale.baseName
  }
  else if (value[locale.language]) {
    fLocale = locale.language
  }

  return value[fLocale]
}


export default getValue
